import * as Sentry from '@sentry/browser';
import { clientApi } from './clientApi';

export async function getOrganizationData(organizationId) {
  try {
    return await clientApi(`/profile/organization/${organizationId}`, 'GET');
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getOrganizationAddresses(organizationId) {
  try {
    return await clientApi(`/profile/address/get_organization_addresses/${organizationId}`, 'GET');
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function inviteToOrganization(body) {
  try {
    return await clientApi('/profile/enterprise/invite', 'POST', null, JSON.stringify(body));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getOrganizationInvites(organizationId) {
  try {
    return await clientApi(`/profile/enterprise/profiles/info/${organizationId}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function deleteOrganizationRequest(organizationId) {
  try {
    return await clientApi(`/profile/enterprise/delete_request/${organizationId}`, 'DELETE');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function revokeInvite(body) {
  try {
    return await clientApi(
      '/profile/enterprise/invite/revoke',
      'PATCH',
      null,
      JSON.stringify(body),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function resendInvite(body) {
  try {
    return await clientApi(
      '/profile/enterprise/invite/resend',
      'PATCH',
      null,
      JSON.stringify(body),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function removeProfileFromEnterprise(body) {
  try {
    return await clientApi('/profile/enterprise/', 'DELETE', null, JSON.stringify(body));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function removeProfilesFromOrganization(organizationId, payload) {
  try {
    return await clientApi(
      `/license/remove/${organizationId}`,
      'DELETE',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function updateAddress(organizationId, addressData) {
  try {
    let response = await getOrganizationAddresses(organizationId);

    if (response && response.status === 200) {
      if (Array.isArray(response.data) && response.data.length) {
        const existingAddress = response.data.find((address) => address.type === addressData.type);
        if (existingAddress) {
          return await clientApi(
            `/profile/address/${existingAddress.id}`,
            'PATCH',
            null,
            JSON.stringify(addressData),
          );
        }
      }
    }

    response = await clientApi('/profile/address', 'POST', null, JSON.stringify(addressData));

    if (response && response.status === 201) {
      return await clientApi(
        '/profile/address/add_address_to_organization',
        'POST',
        null,
        JSON.stringify({ organization_id: organizationId, address_id: response.data.id }),
      );
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function deleteOrganizationAddress(organizationId) {
  try {
    return await clientApi(`/profile/address/delete_from_organization/${organizationId}`, 'DELETE');
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateOrganization(organizationId, body) {
  try {
    return await clientApi(
      `/profile/organization/${organizationId}`,
      'PATCH',
      null,
      JSON.stringify(body),
    );
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateLinkedProfile(profileId, body) {
  try {
    return await clientApi(`/license/update/${profileId}`, 'PATCH', null, JSON.stringify(body));
  } catch (e) {
    Sentry.captureException(e);
  }
}

/**
 * Asynchronous function to retrieve the organization ID where a profile is an enterprise admin.
 *
 * @param {Object} req - Request object.
 * @param {Object} res - Response object.
 * @param {string} profileId - The ID of the profile with a Enterprise Admin Claim for which the organization ID is to be retrieved.
 * @returns {Promise<Object>} - A Promise that resolves to the response from the serverSideApi call.
 *
 * @throws {Error} - If an error occurs during the API call, it is captured and reported using Sentry.
 */
export async function getOrganizationIdEnterpriseAdmin(profileId) {
  try {
    return await clientApi(`/profile/enterprise/enterprise_admin/${profileId}`, 'GET');
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getOrganizationBundle(organizationId) {
  try {
    return await clientApi(`/license/organization_bundle/${organizationId}`, 'GET');
  } catch (e) {
    Sentry.captureException(e);
  }
}

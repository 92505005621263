import { userStore } from '@context/userStore';
import DetailList from '@elements/DetailList';
import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import AddressLookup from '@forms/AddressLookup';
import { classNames } from '@helpers/classNames';
import { getProfileAddresses, updateAddress } from '@providers/profile';
import { useEffect, useState } from 'react';

export default function AddressUpdateForm({ isOnboarding }) {
  const { profileId } = userStore();
  const [address, setAddress] = useState(null);
  const [billingFormData, setBillingFormData] = useState({
    orderHouseNumber: '',
    toevoeging: '',
    orderPostCode: '',
  });
  const [houseNumber, setHouseNumber] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState({ value: 'Nederland' });
  const [selectedShippingCountry, setSelectedShippingCountry] = useState({ value: 'Nederland' });
  const [shippingAddress, setShippingAddress] = useState(null);
  const [shippingFormData, setShippingFormData] = useState({
    orderHouseNumber: '',
    toevoeging: '',
    orderPostCode: '',
  });
  const [shippingHouseNumber, setShippingHouseNumber] = useState(0);
  const [showBillingAddressModal, setShowBillingAddressModal] = useState(false);
  const [showShippingAddressModal, setShowShippingAddressModal] = useState(false);

  const [addressBilling, setAddressBilling] = useState([]);
  const [addressShipping, setAddressShipping] = useState([]);
  const [isValid, setIsValid] = useState({});

  function formatAddress(address) {
    return [
      {
        label: 'Straat + huisnummer',
        value: `${address?.street} ${address?.number}${address?.number_suffix ? ' ' + address.number_suffix : ''}`,
      },
      { label: 'Postcode + stad', value: `${address?.postcode}` },
      { label: 'Plaats', value: address?.city },
      { label: 'Land', value: 'Nederland' },
    ];
  }

  const getAddressData = async () => {
    const response = await getProfileAddresses();

    let shippingAddressData;
    let billingAddressData;
    if (response && response.status === 200) {
      const shippingData = response.data.find((address) => address.type === 'SHIPPING_ADDRESS');
      const billingData = response.data.find((address) => address.type === 'BILLING_ADDRESS');

      if (shippingData) {
        setShippingFormData({
          orderPostCode: shippingData.postcode,
          orderHouseNumber: shippingData.number,
          toevoeging: shippingData.number_suffix ?? '',
        });
        shippingAddressData = formatAddress(shippingData);
        setAddressShipping(shippingAddressData);
      }

      if (billingData) {
        setBillingFormData({
          orderPostCode: billingData.postcode,
          orderHouseNumber: billingData.number,
          toevoeging: billingData.number_suffix ?? '',
        });
        billingAddressData = formatAddress(billingData);
        setAddressBilling(billingAddressData);
      }
    }
  };

  const handleUpdateAddress = async (addressType, formData, addressData) => {
    let response;

    const payload = {
      number: parseInt(formData.orderHouseNumber),
      number_suffix: formData.toevoeging,
      postcode: formData.orderPostCode.replace(/\s/g, ''),
      city: addressData.city,
      municipality: addressData.city,
      street: addressData.street,
      // Province is hard coded because the postal code lookup only returns street and city
      province: 'N/A',
      // Location is hard coded for now because I don't know what is is being used for.
      location: {
        coordinates: [1.1],
        type: 'N/A',
      },
    };

    if (addressType === 'billing') {
      payload.type = 'BILLING_ADDRESS';
      response = await updateAddress(profileId, payload);
    } else {
      payload.type = 'SHIPPING_ADDRESS';
      response = await updateAddress(profileId, payload);
    }

    if (response && response.status === 200) {
      addressType === 'billing'
        ? setShowBillingAddressModal(false)
        : setShowShippingAddressModal(false);
      await getAddressData();
    }
  };

  useEffect(() => {
    let fetchData;
    fetchData = async () => {
      await getAddressData();
    };
    fetchData();
  }, [profileId]);

  const onClickBillingAddressModal = () => {
    setShowBillingAddressModal(!showBillingAddressModal);
  };

  const onClickShippingAddressModal = () => {
    setShowShippingAddressModal(!showShippingAddressModal);
  };

  return (
    <div className="container mx-auto pb-10 font-primary">
      <div className="flex justify-between">
        <div className="flex flex-col text-primary-dark">
          {!isOnboarding && <h2 className="font-primary text-3xl">Adresgegevens</h2>}
          <p className={classNames('mb-8 mt-1 ', isOnboarding && 'font-light')}>
            Bekijk hier je adresgegevens.
          </p>
        </div>
        {/* BillingAddress */}
        <div className="mt-1">
          <Button
            type="button"
            icon="pencil"
            buttonType="secondary"
            onClick={onClickBillingAddressModal}
          />
        </div>
      </div>
      <ul className="w-full divide-y divide-slate-200 rounded-lg bg-white shadow-lg">
        <DetailList details={addressBilling} />
      </ul>

      <Modal
        showModal={showBillingAddressModal}
        setShowModal={setShowBillingAddressModal}
        modalTitle="Wijzig mijn adres"
        modalContent={
          <div>
            <AddressLookup
              setAddress={setAddress}
              address={address}
              setHouseNumber={setHouseNumber}
              houseNumber={houseNumber}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
              setFormData={setBillingFormData}
              formData={billingFormData}
              isValid={isValid}
              setIsValid={setIsValid}
            />
            <div className="flex justify-end gap-4 pt-4">
              <Button
                label="Annuleren"
                type="button"
                buttonType="secondary"
                onClick={() => setShowBillingAddressModal(false)}
              />
              <Button
                label="Versturen"
                type="button"
                buttonType="primary"
                onClick={async () => await handleUpdateAddress('billing', billingFormData, address)}
                disabled={!address || !isValid['orderPostCode'] || !isValid['orderHouseNumber']}
              />
            </div>
          </div>
        }
      />

      {/* ShippingAddress */}
      <>
        <div className="flex justify-between pb-8 pt-6">
          <div className="flex flex-col">
            <h2 className="font-primary text-3xl">Bezorgadres</h2>
            <p className="mt-1">Bekijk hier je bezorgadres.</p>
          </div>
          <div className="mt-1">
            <Button
              type="button"
              icon="pencil"
              buttonType="secondary"
              onClick={onClickShippingAddressModal}
            />
          </div>
        </div>
        <ul className="w-full divide-y divide-slate-200 rounded-lg bg-white shadow-lg">
          <DetailList details={addressShipping} />
        </ul>
      </>
      <Modal
        showModal={showShippingAddressModal}
        setShowModal={setShowShippingAddressModal}
        modalTitle="Wijzig mijn bezorgadres"
        modalContent={
          <>
            <AddressLookup
              setAddress={setShippingAddress}
              address={shippingAddress}
              setHouseNumber={setShippingHouseNumber}
              houseNumber={shippingHouseNumber}
              setSelectedCountry={setSelectedShippingCountry}
              selectedCountry={selectedShippingCountry}
              setFormData={setShippingFormData}
              formData={shippingFormData}
              isValid={isValid}
              setIsValid={setIsValid}
            />
            <div className="flex justify-end gap-4 pt-4">
              <Button
                label="Annuleren"
                type="button"
                buttonType="secondary"
                onClick={() => setShowShippingAddressModal(false)}
              />
              <Button
                label="Versturen"
                type="button"
                buttonType="primary"
                onClick={async () =>
                  await handleUpdateAddress('shipping', shippingFormData, shippingAddress)
                }
                disabled={
                  !shippingAddress || !isValid['orderPostCode'] || !isValid['orderHouseNumber']
                }
              />
            </div>
          </>
        }
      />
    </div>
  );
}
